import React from 'react';
import HeroSection from '../components/HeroSection';

const Home = () => {
    return (
        <>
            <HeroSection />
        </>
    )
}

export default Home;
