import img from "../../images/porfirio.jpg";
import img2 from "../../images/porfirio26bb.jpg";
import img3 from "../../images/porfiriowithsign.jpeg";
import img4 from "../../images/game_thumbnail.png";
import img5 from "../../images/booklet.png";
import img6 from "../../images/image006.png";


export const homeObjTwo = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headline: "Wrapped In Color Lecture Series (videos)",
  buttonLabel: "",
  imgStart: false,
  img: img3,
  alt: "link thumbnail",
  dark: true,
  primary: true,
  darkText: false,
  link: "https://www.youtube.com/playlist?list=PLvpN7OLy7kLu_mG7MDLamlApQJcNX0ee4",
};

export const homeObjThree = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headline: "Ancient Weaving with Porifiro Gutiérrez (PBS)",
  buttonLabel: "",
  imgStart: false,
  img: img2,
  alt: "Porfirio Gutiérrez",
  dark: true,
  primary: true,
  darkText: false,
  link: "https://www.youtube.com/watch?v=b-9RrN3mPcw",
};

export const homeObjFour = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headline: "The Man Preserving Endangered Colours (BBC)",
  buttonLabel: "",
  imgStart: false,
  img: img,
  alt: "Weaving fabrics",
  dark: true,
  primary: true,
  darkText: false,
  link: "https://www.bbc.com/travel/article/20210823-the-man-preserving-endangered-colours",
};

export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headline: "Design Your Own Saltillo Sarape (Game)",
  buttonLabel: "",
  imgStart: false,
  img: img4,
  alt: "Sarape Designing Game",
  dark: true,
  primary: true,
  darkText: false,
  link: "https://eliacuna.itch.io/design-your-own-sarape",
};

export const homeObjFive = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headline: "Exhibit Booklet (PDF download)",
  buttonLabel: "",
  imgStart: false,
  img: img5,
  alt: "Exhibit Booklet for Wrapped in Color",
  dark: true,
  primary: true,
  darkText: false,
  link: "https://statemuseum.arizona.edu/exhibit/wrapped-in-color-booklet",
};

export const homeObjSix = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headline: "Porfirio Gutiérrez Studio (website)",
  buttonLabel: "",
  imgStart: false,
  img: img6,
  alt: "Porfirio Gutiérrez Studio Website Link",
  dark: true,
  primary: true,
  darkText: false,
  link: "https://porfiriogutierrez.com/",
};